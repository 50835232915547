import React from "react"
import { ButtonBase } from "@material-ui/core"

export const ButtonImage = props => {
  const { src, selected, handleChange, isSmall } = props
  return (
    <ButtonBase
      style={{
        width: 84,
        height: 54,
        border: selected ? "2px solid white " : "",
        marginTop: isSmall ? 0 : 10,
        marginRight: isSmall ? 10 : 0,
        borderRadius: 2,
      }}
      disabled={selected}
      onClick={() => handleChange(src)}
    >
      <img
        alt=""
        src={src.fluid.src}
        style={{ width: 76, height: 47, borderRadius: 2 }}
      />
    </ButtonBase>
  )
}
