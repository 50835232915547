import { withWidth } from "@material-ui/core"
import React, { useState } from "react"
import { graphql } from "gatsby"
import { CarouselWithImageBlocks } from "../../components/CarouselWithImageBlocks"
import Layout from "../../components/Layout"
import RealEstateTabs from "../../components/RealEstateTabs"
import "../../styles/commonStyleSheet.scss"
import { useSelector } from "react-redux"
export const query = graphql`
  query($slug: String, $locale: String) {
    contentfulOpportunityPages(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      slug
      investmentCards {
        _id: contentful_id
        title
        categoryName
        location
        price
        area
        locationImages {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

const Opportunities = ({
  location,
  width,
  data: { contentfulOpportunityPages },
  pageContext,
}) => {
  const locale = useSelector(state => state?.locale?.localeObj?.locale)
  const { investmentCards } = contentfulOpportunityPages
  const actualInvestmentCards = {}

  const categories = [
    ...new Set(investmentCards.map(({ categoryName }) => categoryName)),
  ]
  // eslint-disable-next-line array-callback-return
  categories.map(category => {
    const sameCategoryCards = investmentCards.filter(({ categoryName }) => {
      return categoryName === category
    })
    actualInvestmentCards[category] = [...sameCategoryCards]
  })
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState(actualInvestmentCards)
  const isSmall = width === "xs" || width === "sm"
  const [images, setImages] = useState(
    actualInvestmentCards[categories[0]][0].locationImages
  )

  return (
    <Layout
      isHeaderDisable={true}
      location={location}
      pageContext={pageContext}
    >
      <div
        className={locale === "zh" && "chinese-version"}
        style={
          isSmall
            ? {
                marginLeft: 20,
                marginRight: 20,
                minHeight: "100vh",
                overflow: "hidden",
              }
            : {
                marginLeft: 40,
                marginRight: 40,
                height: "100vh",
                overflow: "hidden",
              }
        }
      >
        <CarouselWithImageBlocks width={width} images={images} />
        <RealEstateTabs data={data} onCardClick={setImages} isSmall={isSmall} />
      </div>
    </Layout>
  )
}
export default withWidth()(Opportunities)
