import React, { useState, useEffect } from "react"
import CheckoutAppbar from "../CheckoutAppbar/CheckoutAppbar.component"
import { Grid } from "@material-ui/core"
import { ButtonImage } from "./ButtonImage"
const CarouselWithImageBlocks = props => {
  const { width, images } = props
  const isSmall = width === "xs" || width === "sm"
  const [bg, setBg] = useState(images[0])
  useEffect(() => {
    setBg(images[0])
  }, [images])

  return (
    <Grid
      style={{
        height: 500,
        backgroundImage: `url(${bg.fluid.src})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      container
      justify={isSmall ? "center" : "flex-end"}
      spacing={2}
    >
      <Grid item xs={12}>
        <CheckoutAppbar
          backgroundcolor="transparent"
          Textcolor="white"
          compact
        />
      </Grid>
      {images.length > 0 && (
        <Grid
          item
          xs={isSmall ? 11 : 1}
          style={isSmall ? { display: "flex", alignItems: "flex-end" } : {}}
        >
          <div
            style={
              isSmall
                ? {
                    overflowX: "auto",
                    maxWidth: 300,
                    display: "flex",
                  }
                : {
                    overflowY: "auto",
                    maxHeight: 300,
                  }
            }
          >
            {images.map(image => (
              <ButtonImage
                src={image}
                selected={image === bg}
                handleChange={img => setBg(img)}
                isSmall={isSmall}
              />
            ))}
          </div>
        </Grid>
      )}
    </Grid>
  )
}
export { CarouselWithImageBlocks }
