import React from "react"
import { Box, withWidth } from "@material-ui/core"
import ReactHtmlParser from "react-html-parser"

const styles = {
  name: {
    color: "black",
    fontSize: 16,
    fontFamily: "MabryProRegular",
  },
  location: {
    color: "black",
    fontSize: 16,
    fontFamily: "MabryProLight",
  },
  area: {
    color: "black",
    fontSize: 12,
    fontFamily: "MabryProLight",
    textTransform: "uppercase",
    marginTop: 12,
  },
}
const RealEstateCards = props => {
  const { data, onCardClick } = props
  const { title, location, price, area, _id } = data
  const { width } = props
  const isSmall = width === "xs" || width === "sm"
  return data ? (
    <Box
      onClick={() => onCardClick(_id)}
      p={3}
      width={isSmall ? 260 : 386}
      height={isSmall ? 102 : "auto"}
      border={1}
      borderColor=" rgba(1,2,4,0.08)"
      style={{ cursor: "pointer" }}
      borderRadius={2}
      mr={3}
      boxShadow={0}
    >
      <Box display="flex" width="inherit">
        <img alt="" src={data.locationImages?.[0].fluid.src} width={100} height={80} />
        <Box pl={3}>
          <div style={styles.name}>{title}</div>
          <div style={styles.location}>{ReactHtmlParser(location)}</div>
          <div style={{ ...styles.location, textTransform: "capitalize" }}>
            {ReactHtmlParser(price)}
          </div>
          <div style={styles.area}>{area}</div>
        </Box>
      </Box>
    </Box>
  ) : (
    <div />
  )
}

export default withWidth()(RealEstateCards)
