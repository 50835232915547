import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import RealEstateCards from "./RealEstateCards"
import { Zoom } from "@material-ui/core"

import "react-multi-carousel/lib/styles.css"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Zoom in={value === index}>
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            p={3}
            pl={1}
            display="flex"
            flexWrap="no-wrap"
            pb={0}
            mb={3}
            overflow="auto"
          >
            {children}
          </Box>
        )}
      </Typography>
    </Zoom>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    backgroundColor: "transparent",
    marginLeft: 60,
    marginTop: 30,
  },
  rootSm: {
    flexGrow: 1,
    backgroundColor: "transparent",
    marginTop: 30,
  },
  tabLabel: {
    color: "black",
    fontSize: 16,
    fontFamily: "MabryProLight",
    textTransform: "capitalize",
    textAlign: "left",
    minWidth: 77,
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "black",
    },
  },
}))

export default function RealEstateTabs(props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const { data, onCardClick, isSmall } = props

  let firstKey = data && Object.keys(data)[0]
  const [selected, setSelected] = React.useState(data && data[firstKey][0]._id)

  const layTabs = () => {
    let Tabs = []
    if (data) {
      Object.entries(data).forEach(([key], index) => {
        Tabs.push(
          <Tab label={key} {...a11yProps(index)} className={classes.tabLabel} />
        )
      })
    }
    return Tabs
  }


  useEffect(() => {
    if (data) {
      let firstKey = Object.keys(data)[0]
      setSelected(data && data[firstKey][0]._id)
    }
  }, [data])

  const handleChange = (event, newValue) => {
    setValue(newValue)
    let desiredKey = Object.keys(data)[newValue]
    let photos = data[desiredKey][0].locationImages
    let selectedId = data[desiredKey][0]._id
    onCardClick(photos)
    setSelected(selectedId)
  }

  return (
    <div className={isSmall ? classes.rootSm : classes.root}>
      <AppBar
        position="static"
        style={{ backgroundColor: "transparent" }}
        elevation={0}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          TabIndicatorProps={{
            className: classes.indicator,
            // children: <img src={toursImage} />,
            children: <div />,
          }}
        >
          {layTabs()}
        </Tabs>
        {/* {layTabPanels()} */}

        {Object.entries(data).map(([key], index) => {
          return (
            <TabPanel value={value} index={index}>
              {data &&
                data[key].map(dataItem => {
                  return (
                    <RealEstateCards
                      data={dataItem}
                      onCardClick={id => {
                        onCardClick(
                          data[key].filter(item => item._id === id)[0]
                            .locationImages
                        )
                        setSelected(id)
                      }}
                      selected={selected}
                    />
                  )
                })}
            </TabPanel>
          )
        })}
      </AppBar>
    </div>
  )
}
